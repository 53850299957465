import React from "react"
import Layout from "../containers/layout/layout"
import SEO from '../components/seo'
import Header from '../containers/layout/header'
import ServicesTitleArea from '../containers/services/services-title-area'
import ServicesBannerArea from '../containers/services/services-banner-area'
import ServicesContentArea from '../containers/services/services-content-area'
import InstagramWrap from '../containers/global/instagram'
import Footer from '../containers/layout/footer'
 
const ServicesPage = () => (
  <Layout>
    <SEO 
        title="Services" 
        description="This is the home page to find the services available to access from Spectrum House"
        keywords={["psychologist", "psychology", "autism", "anxiety", "spectrum house", "mental health", "children", "assessment", "diagnosis", "training", "schools", "webinar", "students", "teachers", "training", "education", "primary", "secondary", "school", "adhd", "child"]}
    />
    <Header/>
    <div className="main-content">
        <ServicesTitleArea/>
        <ServicesBannerArea/>
        <ServicesContentArea/>
        <InstagramWrap/>
    </div>
    <Footer/>
  </Layout>
)

export default ServicesPage
