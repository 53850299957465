import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import Image from '../../../components/image'
import {ServicesList, ServicesContentWrap, ServicesContentTop, ServicesContentMain, ServicesContentBottom, ServicesContentImage} from './services-content-area.stc'

const ServicesContentArea = (props) => {
    const servicesData = useStaticQuery(graphql `
        query ServicesContentQuery {
            servicesJson(id: {eq: "services-page-content"}) {
                services_1_image {
                    childImageSharp {
                        fluid(maxWidth: 1200, maxHeight: 280, quality: 100, srcSetBreakpoints: 6) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
                services_1_content_top {
                    heading
                    content {
                        text1
                    }
                }
                services_1_list {
                    heading
                    items
                }
                services_1_content_main {
                    heading
                    content {
                        text1
                        text2
                        text3
                    }
                }
                services_2_image {
                    childImageSharp {
                        fluid(maxWidth: 1200, maxHeight: 280, quality: 100, srcSetBreakpoints: 6) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
                services_2_content_top {
                    heading
                    content {
                        text1
                        text2
                        text3
                        text4
                        text5
                        text5_link
                    }
                }
                services_2_list {
                    heading
                    items
                }
                services_2_content_main {
                    content {
                        text1
                        text2
                        text3
                    }
                }
                services_3_image {
                    childImageSharp {
                        fluid(maxWidth: 1200, maxHeight: 280, quality: 100, srcSetBreakpoints: 6) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
                services_3_content {
                    heading
                    content {
                        text1
                        text2
                        text3
                        text4                        
                    }
                }
                services_3_list {
                    heading
                    items
                }
            }
        }
    `)
    const {services_1_content_top, services_1_list, services_1_content_main, services_2_content_top, services_2_list, services_2_content_main, services_3_content, services_3_list} = servicesData.servicesJson
    const services1Img = servicesData.servicesJson.services_1_image.childImageSharp.fluid;
    const services2Img = servicesData.servicesJson.services_2_image.childImageSharp.fluid;
    const services3Img = servicesData.servicesJson.services_3_image.childImageSharp.fluid;
    const {topHeadingCSS, textCSS, listHeadingCSS} = props;
    return (
        <ServicesContentWrap>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} xl={8}>
                        <ServicesContentImage>
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <Image fluid={services1Img} alt={services_1_content_top.heading}/>
                                    </Col>
                                </Row>
                            </Container>
                        </ServicesContentImage>
                        {services_1_content_top && (
                            <ServicesContentTop>
                                {services_1_content_top.heading && <Heading {...topHeadingCSS}>{services_1_content_top.heading}</Heading>}
                                {services_1_content_top.content.text1 && <Text {...textCSS}>{services_1_content_top.content.text1}</Text>}
                            </ServicesContentTop>
                        )}
                        <Row>
                            {services_1_list && (
                                <Col lg={10}>
                                    {services_1_list.heading && <Heading {...listHeadingCSS}>{services_1_list.heading}</Heading>}
                                    {services_1_list.items && (
                                        <ServicesList>
                                            {services_1_list.items.map((item, i) => <li key={`left-list-${i}`}>{item}</li>)}
                                        </ServicesList>
                                    )}
                                </Col>
                            )}
                        </Row>
                        {services_1_content_main && (
                            <ServicesContentMain>
                                {services_1_content_main.content.text1 && <Text {...textCSS}>{services_1_content_main.content.text1}</Text>}
                                {services_1_content_main.content.text2 && <Text {...textCSS}>{services_1_content_main.content.text2}</Text>}
                                {services_1_content_main.content.text3 && <Text {...textCSS}>{services_1_content_main.content.text3}</Text>}
                            </ServicesContentMain>
                        )}
                        <ServicesContentImage>
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <Image fluid={services2Img} alt={services_2_content_top.heading}/>
                                    </Col>
                                </Row>
                            </Container>
                        </ServicesContentImage>
                        {services_2_content_top && (
                            <ServicesContentBottom>
                                {services_2_content_top.heading && <Heading {...topHeadingCSS}>{services_2_content_top.heading}</Heading>}
                                {services_2_content_top.content.text1 && <Text {...textCSS}>{services_2_content_top.content.text1}</Text>}
                                {services_2_content_top.content.text2 && <Text {...textCSS}>{services_2_content_top.content.text2}</Text>}
                                {services_2_content_top.content.text3 && <Text {...textCSS}>{services_2_content_top.content.text3}</Text>}
                                {services_2_content_top.content.text4 && <Text {...textCSS}>{services_2_content_top.content.text4}</Text>}
                                {services_2_content_top.content.text5 && <Text {...textCSS}>{services_2_content_top.content.text5}</Text>}
                                {services_2_content_top.content.text5_link && <Text {...textCSS}><div dangerouslySetInnerHTML={{ __html: services_2_content_top.content.text5_link}}/></Text>}
                            </ServicesContentBottom>
                        )}
                        <Row>
                            {services_2_list && (
                                <Col lg={10}>
                                    {services_2_list.heading && <Heading {...listHeadingCSS}>{services_2_list.heading}</Heading>}
                                    {services_2_list.items && (
                                        <ServicesList>
                                            {services_2_list.items.map((item, i) => <li key={`left-list-${i}`}>{item}</li>)}
                                        </ServicesList>
                                    )}
                                </Col>
                            )}
                        </Row>
                        {services_2_content_main && (
                            <ServicesContentMain>
                                {services_2_content_main.content.text1 && <Text {...textCSS}>{services_2_content_main.content.text1}</Text>}
                                {services_2_content_main.content.text2 && <Text {...textCSS}>{services_2_content_main.content.text2}</Text>}
                                {services_2_content_main.content.text3 && <Text {...textCSS}>{services_2_content_main.content.text3}</Text>}
                            </ServicesContentMain>
                        )}
                        <ServicesContentImage>
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <Image fluid={services3Img} alt={services_3_content.heading}/>
                                    </Col>
                                </Row>
                            </Container>
                        </ServicesContentImage>
                        {services_3_content && (
                            <ServicesContentBottom>
                                {services_3_content.heading && <Heading {...topHeadingCSS}>{services_3_content.heading}</Heading>}
                                {services_3_content.content.text1 && <Text {...textCSS}>{services_3_content.content.text1}</Text>}
                                {services_3_content.content.text2 && <Text {...textCSS}>{services_3_content.content.text2}</Text>}
                                {services_3_content.content.text3 && <Text {...textCSS}>{services_3_content.content.text3}</Text>}
                                {services_3_content.content.text4 && <Text {...textCSS}>{services_3_content.content.text4}</Text>}
                            </ServicesContentBottom>
                        )}
                        <Row>
                            {services_3_list && (
                                <Col lg={10}>
                                    {services_3_list.heading && <Heading {...listHeadingCSS}>{services_3_list.heading}</Heading>}
                                    {services_3_list.items && (
                                        <ServicesList>
                                            {services_3_list.items.map((item, i) => <li key={`left-list-${i}`}>{item}</li>)}
                                        </ServicesList>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </ServicesContentWrap>
    )
}

ServicesContentArea.defaultProps = {
    topHeadingCSS: {
        as: 'h4',
        color: 'secondaryColor',
        lineHeight: 1.375,
        mb: '17px'
    },
    textCSS: {
        mb: '14px'
    },
    listHeadingCSS: {
        as: 'h5',
        mb: '14px'
    }
}

export default ServicesContentArea


  