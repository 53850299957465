import styled from "styled-components";
import {device} from '../../../theme'

export const ServicesContentWrap = styled.section `
    padding-bottom: 56px;
    ${device.small}{
        padding-bottom: 76px;
    }
    ${device.medium}{
        padding-bottom: 96px;
    }
`;

export const ServicesContentTop = styled.div `
    margin-bottom: 38px;
`;

export const ServicesContentMain = styled.div `
    margin-bottom: 38px;
`;

export const ServicesContentImage = styled.div `
    margin-bottom: 50px;
    img{
        border-radius: 5px;
    }
`;

export const ServicesList = styled.ul `
    margin: 0;
    padding: 0;
    list-style: none;
    li{
        margin-bottom: 11px;
        position: relative;
        padding-left: 18px;
        &:before{
            position: absolute;
            content: "";
            left: 0;
            top: 7px;
            width: 6px;
            height: 6px;
            background: #99999c;
            border-radius: 50%;
        }
    }
`;

export const ServicesContentBottom = styled.div `
    margin-bottom: 38px;
    padding-top: 42px;
    margin-top: 35px;
`;